import {useLocation} from '@remix-run/react';

import {useSharedDataContext} from '~/foundation/AppSetupContext';
import {type AppHandle} from '~/types';

interface appPathArgs {
  path?: string;
  appHandle?: AppHandle;
  includeQueryParams?: boolean;
}

/**
 * Returns the current app path
 * @param appPathArgs.path - Defaults to the current app path if not passed in. Include the leading slash if you want to override the current path.
 * @param appPathArgs.includeQueryParams - When true, appends the current query params to the url
 */
export function useAppPath({
  path,
  appHandle,
  includeQueryParams,
}: appPathArgs = {}) {
  const {pathname, search} = useLocation();
  const {app} = useSharedDataContext();
  const {unifiedAdminEnabled} = useSharedDataContext();
  const base = unifiedAdminEnabled ? '' : '/admin';
  const finalPath = `${base}/apps/${appHandle ?? app.handle}${path ?? pathname}`;
  return includeQueryParams && search
    ? finalPath.concat(`?${search}`)
    : finalPath;
}

/**
 * Returns the current app path encoded for use in a URL
 * @param appPathArgs.path - Defaults to the current app path if not passed in. Include the leading slash if you want to override the current path.
 * @param appPathArgs.includeQueryParams - When true, appends the current query params to the url
 */
export function useEncodedAppPath(args: appPathArgs = {}) {
  const appPath = useAppPath(args);
  return encodeURIComponent(appPath);
}
