import {createContext} from 'react';

import {type Gid, type Scalar} from '~/types';
import {CurrencyCode, StaffMemberPermission} from '~/types/graphql/core-types';

interface ShopDetailsContext {
  id: string;
  name: string;
  contactEmail: string;
  currencyCode: CurrencyCode;
  url: Scalar<'URL'>;
  enabledFlags: Array<boolean>;
  myshopifyDomain: string;
  ianaTimezone: string;
}

export interface SharedDataContextType {
  shop: ShopDetailsContext;
  staffMember: {
    id: number;
    locale: string;
    adminPermissions: StaffMemberPermission[];
  };
  unifiedAdminEnabled: boolean;
  app: {
    id: Gid<'AppInstallation'>;
    appStoreAppUrl: Scalar<'URL'>;
    handle: string;
  };
}

export const SharedDataContext = createContext<
  SharedDataContextType | undefined
>(undefined);
