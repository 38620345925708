import {useCallback, useContext} from 'react';

import {StaffMemberPermission} from '~/types/graphql/core-types';

import {SharedDataContext} from '../context';

export function useSharedDataContext() {
  const allData = useContext(SharedDataContext);

  if (!allData) {
    throw new Error(
      'No data found for SharedDataContext. Make sure you have a SharedDataContext above this component.',
    );
  }

  const {shop, staffMember, ...restOfData} = allData;
  const {enabledFlags, ...restOfShop} = allData.shop;
  const {adminPermissions, ...restOfStaffMember} = allData.staffMember;

  const hasPermissions = useCallback(
    (requiredPermissions: StaffMemberPermission[]) => {
      if (requiredPermissions.length === 0) {
        return true;
      }

      if (adminPermissions.length === 0) {
        return false;
      }

      return requiredPermissions.every((permission) =>
        adminPermissions.includes(permission),
      );
    },
    [adminPermissions],
  );

  return {
    ...restOfData,
    shop: {
      ...restOfShop,
    },
    staffMember: {
      ...restOfStaffMember,
    },
    hasPermissions,
  };
}
