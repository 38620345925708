interface IBetaFlag {
  betaFlag: string;
  optOutFlag: string | undefined;
}

// The hash value can be found inside Services Internal https://app.shopify.com/services/internal/betas
export const discoveryUXRecommendationsFlag = {
  betaFlag: '86a26c5f',
  optOutFlag: undefined,
} as const;

export const discoveryUXSettingsFlag = {
  betaFlag: '57a0026a',
  optOutFlag: undefined,
} as const;

export const BETA_FLAGS_LIST = [
  discoveryUXRecommendationsFlag,
  discoveryUXSettingsFlag,
] as const satisfies Array<IBetaFlag>;

export const ALL_BETA_FLAG_VALUES = BETA_FLAGS_LIST.flatMap(
  (beta: IBetaFlag) => [beta.betaFlag, beta.optOutFlag ?? ''],
);

type InternalBetaFlags = NonNullable<
  (typeof BETA_FLAGS_LIST)[number]['betaFlag']
>;
type InternalOptOutFlags = NonNullable<
  (typeof BETA_FLAGS_LIST)[number]['optOutFlag']
>;

export type BetaFlags = NonNullable<
  InternalBetaFlags | InternalOptOutFlags | ''
>;

export interface BetaFlag {
  readonly betaFlag: InternalBetaFlags;
  readonly optOutFlag: InternalOptOutFlags | undefined;
}

export const isEnabledFlag = (
  feature: BetaFlag | undefined,
  enabledFlags: boolean[],
) => {
  if (!feature) {
    return true;
  }

  const {betaFlag, optOutFlag} = feature;

  // Need to multiply by 2 because the enabledFlags array has an entry for both beta and opt-out flags for each key
  const indexOfFeature =
    BETA_FLAGS_LIST.findIndex(
      (value: BetaFlag) => value.betaFlag === betaFlag,
    ) * 2;
  const isEnabled = enabledFlags[indexOfFeature] ?? false;

  if (optOutFlag) {
    return isEnabled && !enabledFlags[indexOfFeature + 1];
  }

  return isEnabled;
};
