import {useContext} from 'react';

import {
  SharedDataContext,
  useBugsnagContext,
} from '~/foundation/AppSetupContext';
import {type BetaFlag, isEnabledFlag} from '~/utils/betaFlags';

export function useBetaEnabled(feature: BetaFlag | undefined) {
  const data = useContext(SharedDataContext);
  const {addFeatureFlag} = useBugsnagContext();
  if (!data) {
    throw new Error(
      'No data found for SharedDataContext. Make sure you have a SharedDataContext above this component.',
    );
  }

  const isEnabled = isEnabledFlag(feature, data.shop.enabledFlags);

  // Bugsnag does not want us to capture control groups/when a feature flag is off, to enable exclusive filtering.
  // https://docs.bugsnag.com/product/features-experiments/#features-dashboard
  if (isEnabled && feature) {
    addFeatureFlag(feature.betaFlag, 'enabled');
  }
  return isEnabled;
}
