import {
  BlockStack,
  Box,
  Card,
  Divider,
  InlineGrid,
  Layout,
  Page,
  SkeletonBodyText,
} from '@shopify/polaris-internal';
import {useI18n} from '@shopify/react-i18n';
import React from 'react';

import {EnhancedCard, ProductInfo, SkeletonPageActions} from '~/components';
import {useBetaEnabled, useIdFromUrl, usePreviousRouteInfo} from '~/hooks';
import {DetailsPageMode} from '~/types/shared';
import {discoveryUXRecommendationsFlag} from '~/utils/betaFlags';
import {createI18nConfig} from '~/utils/createI18nConfig';
import {productRecommendationsURL} from '~/utils/urls';

import translations from './translations';

export function ProductRecommendationsDetailsSkeleton() {
  const [i18n] = useI18n(createI18nConfig(translations));
  const id = useIdFromUrl();
  const mode = id ? DetailsPageMode.Update : DetailsPageMode.Create;
  const recosImprovementsEnabled = useBetaEnabled(
    discoveryUXRecommendationsFlag,
  );
  const backAction = usePreviousRouteInfo({
    content: i18n.translate(
      'ProductRecommendationsDetailsSkeleton.navigation.productRecommendations',
    ),
    url: productRecommendationsURL(),
  });

  const productSourceMarkup = (
    <Layout.Section>
      <EnhancedCard
        title={
          mode === DetailsPageMode.Create
            ? i18n.translate(
                'ProductRecommendationsDetailsSkeleton.sourceProductCard.title',
              )
            : i18n.translate(
                'ProductRecommendationsDetailsSkeleton.sourceProductCard.updateTitle',
              )
        }
      >
        <ProductInfo
          isLoading
          thumbnailSize={
            recosImprovementsEnabled ? 'extraExtraLarge' : 'extraLarge'
          }
          layout={recosImprovementsEnabled ? 'vertical' : 'horizontal'}
        />
      </EnhancedCard>
    </Layout.Section>
  );

  const complementaryProductsMarkup = (
    <EnhancedCard
      padding="0"
      headerPadding="400"
      background={
        mode === DetailsPageMode.Create ? 'bg-surface-secondary' : 'bg-surface'
      }
      title={
        mode === DetailsPageMode.Create
          ? i18n.translate(
              'ProductRecommendationsDetailsSkeleton.complementaryProductsCard.title',
            )
          : i18n.translate(
              'ProductRecommendationsDetailsSkeleton.complementaryProductsCard.updateTitle',
            )
      }
    >
      {recosImprovementsEnabled ? (
        <Box padding="400" paddingBlockStart="0">
          <SkeletonBodyText lines={1} />
        </Box>
      ) : (
        <>
          <Box padding="400" paddingBlockStart="0">
            <SkeletonBodyText lines={3} />
          </Box>
          <Divider />
          <Box padding="400" background="bg-surface-secondary">
            <SkeletonBodyText lines={1} />
          </Box>
        </>
      )}
    </EnhancedCard>
  );

  const relatedProductsMarkup = (
    <EnhancedCard
      padding="0"
      headerPadding="400"
      background={
        mode === DetailsPageMode.Create ? 'bg-surface-secondary' : 'bg-surface'
      }
      title={
        mode === DetailsPageMode.Create
          ? i18n.translate(
              'ProductRecommendationsDetailsSkeleton.relatedProductsCard.title',
            )
          : i18n.translate(
              'ProductRecommendationsDetailsSkeleton.relatedProductsCard.updateTitle',
            )
      }
    >
      {recosImprovementsEnabled ? (
        <Box padding="400" paddingBlockStart="0">
          <BlockStack gap="400">
            <SkeletonBodyText lines={1} />
            <Card background="bg-surface-secondary">
              <SkeletonBodyText lines={3} />
            </Card>
          </BlockStack>
        </Box>
      ) : (
        <>
          <Box padding="400" paddingBlockStart="0">
            <SkeletonBodyText lines={3} />
          </Box>
          <Divider />
          <Box padding="400">
            <SkeletonBodyText lines={1} />
          </Box>
        </>
      )}
    </EnhancedCard>
  );

  return (
    <Page
      title={
        mode === DetailsPageMode.Create
          ? i18n.translate('ProductRecommendationsDetailsSkeleton.createTitle')
          : i18n.translate('ProductRecommendationsDetailsSkeleton.updateTitle')
      }
      backAction={backAction}
    >
      <Layout>
        {recosImprovementsEnabled ? (
          <Layout.Section>
            <InlineGrid columns={['oneThird', 'twoThirds']}>
              {productSourceMarkup}
              <Layout.Section>
                <BlockStack gap="400">
                  {relatedProductsMarkup}
                  {complementaryProductsMarkup}
                </BlockStack>
              </Layout.Section>
            </InlineGrid>
          </Layout.Section>
        ) : (
          <>
            {productSourceMarkup}
            <Layout.Section>{complementaryProductsMarkup}</Layout.Section>
            <Layout.Section>{relatedProductsMarkup}</Layout.Section>
          </>
        )}
        <Layout.Section>
          <SkeletonPageActions />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
