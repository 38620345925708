import {
  BlockStack,
  Box,
  type BoxProps,
  Button,
  type ButtonProps,
  Card,
  type CardProps,
  InlineGrid,
  InlineStack,
  Text,
} from '@shopify/polaris-internal';
import React, {Children} from 'react';

type TitleType =
  | {
      title: string;
      /** Renders to the right of the title, typically a badge or icon */
      titleDecorator?: React.ReactNode;
      subtitle?: React.ReactNode;
      accessibilityTitle?: never;
    }
  | {
      title?: never;
      titleDecorator?: never;
      subtitle?: never;
      accessibilityTitle: string;
    };

type EnhancedCardProps = CardProps &
  TitleType & {
    actions?: ButtonProps[];
    children?: React.ReactNode;
    /** Padding for just top and sides of the header
     * @default '0'
     */
    headerPadding?: BoxProps['padding'];
  };

export function EnhancedCard({
  title,
  subtitle,
  titleDecorator,
  accessibilityTitle,
  actions,
  headerPadding = '0',
  children,
  ...rest
}: EnhancedCardProps) {
  const actionsMarkup =
    actions && actions.length > 0 ? (
      <InlineStack gap="600" blockAlign="center">
        {actions.map(({id, children, ...rest}, index) => (
          <Button key={id ?? index} {...rest}>
            {children}
          </Button>
        ))}
      </InlineStack>
    ) : null;

  const hasHeaderElement = Boolean(title || actionsMarkup);
  const hasChildren = Children.toArray(children).filter(Boolean).length > 0;

  return (
    <Card {...rest}>
      <BlockStack gap="400">
        <Box
          {...(hasHeaderElement
            ? {
                padding: headerPadding,
                paddingBlockEnd: '0',
              }
            : {
                padding: '0',
              })}
        >
          <InlineGrid columns={{xs: '1fr auto'}} alignItems="center" gap="100">
            <BlockStack gap="050">
              <InlineStack gap="100" align="start">
                <Text visuallyHidden={!title} variant="headingSm" as="h2">
                  {title ?? accessibilityTitle}
                </Text>
                {titleDecorator}
              </InlineStack>
              {subtitle ? (
                <Text variant="bodyMd" tone="subdued" as="span">
                  {subtitle}
                </Text>
              ) : null}
            </BlockStack>
            {actionsMarkup}
          </InlineGrid>
        </Box>
        {hasChildren ? <div>{children}</div> : null}
      </BlockStack>
    </Card>
  );
}
