import {type Gid, type IdOrGidOrDynamicPath, type Resource} from '~/types';
import {
  type MetafieldOwnerType,
  StaffMemberPermission,
} from '~/types/graphql/core-types';

import {normalizeId} from './normalizeId';

const OVERVIEW_URL = '/';
const FILTERS_URL = '/filters';
const SEARCH_URL = '/search';
const PRODUCT_BOOST_URL = `${SEARCH_URL}/product-boosts`;
const SYNONYM_GROUPS_URL = `${SEARCH_URL}/synonyms`;
const PRODUCT_RECOMMENDATIONS_URL = '/product-recommendations';
const SETTINGS_URL = '/settings';
const PRODUCT_SETTINGS_URL = `${SETTINGS_URL}?section=products`;

type URLWithPermissions<TFn extends () => string> = TFn & {
  permissions: StaffMemberPermission[];
};
function createURLWithPermissions<TFunction extends () => string>(
  urlFunction: TFunction,
  permissions: StaffMemberPermission[],
): URLWithPermissions<TFunction> {
  const urlFunctionReturn = urlFunction as TFunction & {
    permissions: StaffMemberPermission[];
  };
  urlFunctionReturn.permissions = permissions;
  return urlFunctionReturn;
}

function getURLFn<TResource extends Resource>(prefix: string) {
  return (id?: IdOrGidOrDynamicPath<TResource>) => {
    return id ? `${prefix}/${normalizeId(id)}` : prefix;
  };
}

export const overviewURL = createURLWithPermissions(() => OVERVIEW_URL, []);

export const filtersURL = createURLWithPermissions(
  getURLFn<'OnlineStoreFilterSetting'>(FILTERS_URL),
  [StaffMemberPermission.Links],
);

export const searchURL = createURLWithPermissions(() => SEARCH_URL, []);

export const productBoostsURL = createURLWithPermissions(
  getURLFn<'Product'>(PRODUCT_BOOST_URL),
  [StaffMemberPermission.ManageProducts],
);

export const synonymGroupURL = createURLWithPermissions(
  getURLFn<'Product'>(SYNONYM_GROUPS_URL),
  [StaffMemberPermission.Links],
);

export const productRecommendationsURL = createURLWithPermissions(
  getURLFn<'Product'>(PRODUCT_RECOMMENDATIONS_URL),
  [StaffMemberPermission.ManageProducts],
);

export const settingsURL = createURLWithPermissions(
  () => SETTINGS_URL,
  [StaffMemberPermission.Links],
);

export const productSettingsURL = createURLWithPermissions(
  () => PRODUCT_SETTINGS_URL,
  [StaffMemberPermission.Links],
);

/* Some function overloads that allow passing in the correct query params to the reports pages */
export function reportsURL(
  handle: 'product_recommendation_low_engagement',
): string;
export function reportsURL(
  handle:
    | 'top_online_store_searches'
    | 'top_online_store_searches_with_no_results'
    | 'search_conversion_funnel'
    | 'product_recommendation_conversions_over_time'
    | 'search_query_level_clicks',
  queryOptions: {since: `-${number}d`},
): string;
export function reportsURL(
  handle: string,
  queryOptions?: Record<string, string>,
) {
  const handleBase = `/reports/${handle}`;
  if (!queryOptions) {
    return handleBase;
  }
  return `${handleBase}?${new URLSearchParams(queryOptions).toString()}`;
}

export function metaobjectURL<
  TMetaobjectDefinition extends {type: string},
  TMetaobject extends {id: Gid<'Metaobject'>},
>(metaobjectDefinition: TMetaobjectDefinition, metaobject: TMetaobject) {
  return `/content/entries/${metaobjectDefinition.type}/${normalizeId(
    metaobject.id,
  )}`;
}

export function metaobjectCreateURL<
  TMetaobjectDefinition extends {type: string},
>(metaobjectDefinition: TMetaobjectDefinition) {
  return `/content/entries/${metaobjectDefinition.type}/new`;
}

export function metaobjectDefinitionURL<
  TMetaobjectDefinition extends {type: string},
>(metaobjectDefinition: TMetaobjectDefinition) {
  return `/settings/custom_data/metaobjects/${metaobjectDefinition.type}`;
}

export function metafieldDefinitionURL<
  TMetafieldDefinition extends {
    ownerType: MetafieldOwnerType;
    id: Gid<'MetafieldDefinition'>;
  },
>(metafieldDefinition: TMetafieldDefinition) {
  return `/settings/custom_data/${metafieldDefinition.ownerType.toLowerCase()}/metafields/${normalizeId(
    metafieldDefinition.id,
  )}`;
}

/**
 * Joins multiple url paths together, getting rid of any duplicate slashes, and preserving the trailing slash if present.
 */
export function joinURLs(...paths: string[]) {
  return paths
    .map((part, index) => {
      const isFirst = index === 0;
      const isLast = index === paths.length - 1;
      const withoutTrailingSlash = part.replace(/\/$/, '');
      const withoutLeadingSlash = part.replace(/^\//, '');
      const withoutEitherSlash = part.replace(/^\//, '').replace(/\/$/, '');

      if (isFirst) {
        return isLast ? part : withoutTrailingSlash;
      }

      if (isLast) {
        return withoutLeadingSlash;
      }

      return withoutEitherSlash;
    })
    .join('/');
}

/**
 * Removes admin-related params from the url, like embedded, hmac, etc.
 * Sorts the remaining params alphabetically.
 * @param url
 */
export function urlParamsNormalizer<T extends string | URL>(url: T): T {
  const newUrl = url instanceof URL ? url : new URL(url);
  const params = newUrl.searchParams;
  [
    'embedded',
    'hmac',
    'host',
    'session',
    'timestamp',
    'locale',
    'shop',
    'id_token',
    'index',
  ].forEach((param) => {
    params.delete(param);
  });
  params.sort();
  return (url instanceof URL ? newUrl : newUrl.toString()) as T;
}
