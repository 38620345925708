import {parseGid} from '@shopify/admin-graphql-api-utilities';
import {type CompositeMonorailEvent} from '@shopify/monorail/lib/events/events';
import {useCallback, useRef} from 'react';

import {
  useBugsnagContext,
  useSharedDataContext,
} from '~/foundation/AppSetupContext';
import {useSessionIdContext} from '~/foundation/SessionIdContext';
import {usePreviousUrl} from '~/hooks';

import {producer} from './config';

function useMonorail() {
  const {notify} = useBugsnagContext();

  const produce = useCallback(
    async (monorailEvent: CompositeMonorailEvent) => {
      try {
        return await producer.produce(monorailEvent);
      } catch (err) {
        const error =
          err instanceof Error
            ? err
            : new Error(`Error publishing event to Monorail: ${err}`);

        notify(error);
      }
    },
    [notify],
  );

  return {produce};
}

type SuggestionAction = 'valuesSuggested' | 'valueAccepted' | 'valueRejected';

export function useFilterSuggestionProducer() {
  const {produce} = useMonorail();
  const {shop, staffMember} = useSharedDataContext();
  const {getSessionId} = useSessionIdContext();

  return useCallback(
    (action: SuggestionAction) => {
      return produce({
        schemaId: 'discovery_app_filter_group_suggested/1.0',
        payload: {
          shopId: Number(parseGid(shop.id)),
          userId: staffMember.id,
          sessionId: getSessionId(),
          action,
        },
      });
    },
    [getSessionId, produce, shop.id, staffMember.id],
  );
}

export type TrackFilterSaveProps = {
  filterSettingId: number;
  sortJourney: string;
} & (
  | {sortType: 'MIXED'; aiRetentionRate: number}
  | {
      sortType: 'UNMODIFIED' | 'AUTOMATIC' | 'AI' | 'MANUAL';
      aiRetentionRate?: never;
    }
);

export function useFilterAiProducer() {
  const {produce} = useMonorail();
  const {notify} = useBugsnagContext();
  const {shop, staffMember} = useSharedDataContext();
  const {getSessionId} = useSessionIdContext();
  const getCommonData = useCallback(
    () => ({
      shopId: Number(parseGid(shop.id)),
      userId: staffMember.id,
      sessionId: getSessionId(),
    }),
    [getSessionId, shop.id, staffMember.id],
  );

  const trackSortError = useCallback(
    ({
      originalValues,
      responseValues,
      errorType,
    }: {
      originalValues: string;
      responseValues: string;
      errorType: 'Removed' | 'Added' | 'Other';
    }) => {
      return produce({
        schemaId: 'discovery_app_ai_filter_sort_error/1.0',
        payload: {
          ...getCommonData(),
          originalValues,
          responseValues,
          errorType,
        },
      });
    },
    [getCommonData, produce],
  );

  const trackFilterSave = useCallback(
    (props: TrackFilterSaveProps) => {
      if (
        !/(AUTOMATIC|MANUAL)(, (AUTOMATIC|MANUAL|AI|CANCELED_AI|DISCARD_AI|DRAG_N_DROP))*$/.test(
          props.sortJourney,
        )
      ) {
        notify(
          `Incorrect sort journey value detected on trackFilterSave: ${props.sortJourney}. Event will be sent anyway`,
        );
      }
      return produce({
        schemaId: 'discovery_app_filter_sort_values/3.0',
        payload: {
          ...getCommonData(),
          ...props,
        },
      });
    },
    [getCommonData, notify, produce],
  );

  return {trackSortError, trackFilterSave};
}

interface TrackingPayloadClick {
  eventType: 'click';
  linkText: string;
  url: string;
  elementId: string;
}

const trackingSchemasMap = {
  click: 'discovery_app_events_user_clicked/2.0',
  pageview: 'discovery_app_events_session_pageview/1.0',
} as const;

export function useTrackingProducer() {
  const {produce} = useMonorail();
  const {shop, staffMember} = useSharedDataContext();
  const {getSessionId} = useSessionIdContext();
  const lastPageViewTime = useRef(Date.now());
  const previousRoute = usePreviousUrl();
  // Use ref to avoid updating trackPageView each time the route changes which can cause double calls
  const previousRouteRef = useRef(previousRoute);
  // Keep ref in sync with the state value
  previousRouteRef.current = previousRoute;
  const getCommonData = useCallback(
    () => ({
      shopId: Number(parseGid(shop.id)),
      userId: staffMember.id,
      sessionId: getSessionId(),
    }),
    [getSessionId, shop.id, staffMember.id],
  );

  const trackPageView = useCallback(() => {
    lastPageViewTime.current = Date.now();
    const pageReferrer = (() => {
      if (previousRouteRef.current) {
        return previousRouteRef.current;
      }
      const params = new URLSearchParams(document.location.search);
      if (!params.get('referrer')) {
        return '';
      }
      // We only care about referrer and install params, everything else is noise.
      return ['referrer', 'install']
        .map((key) => {
          const value = params.get(key);
          return value ? `${key}=${value}` : null;
        })
        .filter(Boolean)
        .join('&');
    })();
    return produce({
      schemaId: trackingSchemasMap.pageview,
      payload: {
        ...getCommonData(),
        currentPage: document.location.pathname,
        pageReferrer,
      },
    });
  }, [getCommonData, produce]);

  const trackEvent = useCallback(
    (payload: TrackingPayloadClick) => {
      return produce({
        schemaId: trackingSchemasMap[payload.eventType],
        payload: {
          ...getCommonData(),
          ...payload,
          pageDurationUpToEvent: Date.now() - lastPageViewTime.current,
        },
      });
    },
    [getCommonData, produce],
  );
  return {trackPageView, trackEvent};
}
